@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import "./variables.modules";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 1.5vh;
    width: 100%;
    height: 100%;
    color: $dark;
    // background-color: red;
    box-sizing: border-box;


}




li {
    color: $dark;
}



a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
}

ul {
    list-style-type: none;
}

.cursor__dot div {
    z-index: 999999 !important;
}

.cursor__dot div:last-child {
    background-color: #2e49f5 !important;
}

.cursor__dot div:first-child {
    // filter: invert(1);
    background-color: transparentize($color: #408383, $amount: 0.5) !important;
    // color : #ccf5ec !important;
    // opacity: 0.5;
}

.contact__container{
    display: flex;

    height: 80vh;
    align-items: center;
    justify-content: space-around;
}
.contact__left{
    display: flex;
    // align-items: start;
    // flex-direction: left;
    flex-direction: column;

    gap: 20px;
}
.contact__left__title h2{
font-weight: 600;
font-size: 40px;
margin-bottom: 5px;
}
.contact__left__title hr{
    border: none;
    width: 120px;
    height: 5px;
    background :linear-gradient(to left, rgb(0,255,164), rgb(166,104,255));
    border-radius: 10px;
    margin-bottom: 20px;
}

.contact__input{
    width: 400px;
    height: 50px;
    border: none;
    outline: none;
    padding-left: 25px;
    font-weight: 500;
    color: #666;
    border-radius: 50px;
}
.contact__left textarea{
    height: 140px;
    padding-top: 15px;
    border-radius: 20px;
}
.contact__input:focus{
    border: 2px solid rgb(0, 195, 255);
}
.contact__input::placeholder{
    color: #a9a9a9;
}
.contact__left button{
    display: flex;
    width:120px;
    align-items: center;
    padding: 15px 30px;
    font-size: 16px;
    color: #fff;
    gap:10px;
    border: none;
    border-radius: 50px;
    background: linear-gradient(to left, rgb(0,255,164), rgb(166,104,255));
    cursor: pointer;
}
.contact__left button img{
    height: 15px;
}
.contact__right{
    width: 50%;
}
.contact__right img{
    width:80%;
    filter: drop-shadow(0px 5px 5px );



}
.container__submitted{
    width: 400px;
    background: #fff;
    border-radius: 6px;
    position: absolute;
    top: 0;
    left: 50%;
    // transform: translate(-50%, -50%) scale(1);
    // text-align: center;
    padding: 0 30px 30px;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 20px 5000px 5000px 5000px rgba(0, 0, 0, 0.5);

    visibility: hidden;
    // gap: 20px;
}

.container__submitted img{
    width: 100px;
    margin-top: -30px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(94, 92, 92, 0.2);
}
.container__submitted h2{
font-size: 38px;
font-weight: 500;
margin: 30px 0 10px;
}
.container__submitted button{
    width: 100%;
    margin-top: 50px;
    padding: 10px 0;
    background: linear-gradient(to left, rgb(0,255,164), rgb(166,104,255));
    color: #fff;
    border: none;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);

}


// .contact__container__submitted button{
// // not clickable
//     pointer-events: none;
// }

@media (max-width: 768px) {
    .contact__input{
        width: 80vw;
    }
//     .contact__right img{
//         display: none;
//     }
}
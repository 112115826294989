@import "../variables.modules";

nav {
  transition: all 400ms;
  z-index: 1;
  font-family: Avantgarde, TeX Gyre Adventor, URW Gothic L, sans-serif;
}

.dark li{
  // background-color: $dark;
  text-transform: capitalize;
}


.light li{
  // background-color: $white;
  text-transform: capitalize;
}

.active {
  border-bottom: 5px solid;
  border-image-slice: 1;
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.link:hover {
  color: unset;
}
.logo{
  width: 100px;
  height: 100px;
}



.gradient_border {
  margin: 0 auto; /* This centers the element horizontally */
  position: relative;
  padding: 10px; /* Adjust padding as needed */
  // border: 5px solid transparent; /* Make border transparent */
  display: block; /* Ensures the element behaves as a block-level element */
}

.gradient_border::after {
  content: '';
  text-align: center; /* This centers the content inside the element */
  position: absolute;
  bottom: 0;
  left: 50%; /* Start from the center */
  transform: translateX(-50%); /* Shift the gradient to the center */
  width: 80%; /* Width of the gradient */
  height: 4px; /* Thickness of the border */
  background: linear-gradient(to left, rgb(0,255,164), rgb(166,104,255)); 
  opacity: 0.5;
}

@import "../../variables.modules";

h1 {
  font-size: 2rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 3rem;
    text-align: left;
  }
}

h2 {
  font-size: 1.25rem;
  text-align: center;
  @media only screen and (min-width: 940px) {
    font-size: 2rem;
    text-align: left;
  }
}


.hand {
  animation-name: wave;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
}

.email_container {
  display: flex;
  align-items: center;
}
.email_container button {
  background: none;
  border: none;
  cursor: pointer;
  // margin-lef: 10px;
}
.email_container i {
  font-size: 20px;
  transition: color 0.3s ease;
}
// .email_container i .fa-check {
//   border: 1px solid gray;
//   color: green; /* Change to desired color for the checkmark icon */
// }
// .email_container i .fa-copy {
//   // padding: 10;
//   color: gray; /* Change to desired color for the copy icon */
// }


a {
  color: $dark;
  transition: color 200ms ease;
}

a:hover {
  color: purple;
  transition: color 200ms ease;
}

@keyframes wave {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}
$purple: #8D53FF;
$pink: #CA6BE6;
$white: #f8f8f8;
$dark: #1f1f1f;
$red: #FF6057;
$yellow: #FFBD2E;
$green: #27C93F;
$lightgray: #c9c9c9;

$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);
}
// body{
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
//     -webkit-font-smoothing: antialiased;
// }
// *, ::after, ::before {
//   box-sizing: border-box;
// }
// body::before {
//   content: "";
//   height: 100vh;
//   width: 100vw;
//   color: rgba(113, 113, 113, 0.07);
//   position: fixed;
//   z-index: -1;
//   background: linear-gradient(currentcolor 1px, transparent 1px) 0% 0% / 94px 90px, linear-gradient(to right, currentcolor 1px, transparent 1px) transparent;
//   transition: 0.5s linear;
// }

// let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

.gradient-border {
  margin: 0 auto; /* This centers the element horizontally */
  position: relative;
  padding: 10px; /* Adjust padding as needed */
  // border: 5px solid transparent; /* Make border transparent */
  display: block; /* Ensures the element behaves as a block-level element */
}

.gradient-border::after {
  content: '';
  text-align: center; /* This centers the content inside the element */
  position: absolute;
  bottom: 0;
  left: 50%; /* Start from the center */
  transform: translateX(-50%); /* Shift the gradient to the center */
  width: 80%; /* Width of the gradient */
  height: 2px; /* Thickness of the border */
  background: linear-gradient(to left, rgb(0,255,164), rgb(166,104,255)); 
}

@import "../variables.modules";

footer {
  color: $dark;
}

.dark {
  // background-color: $dark;
  // background-image: linear-gradient(315deg, $dark 0%, $purple 74%);
  background-image: linear-gradient(to right, #243949 0%, #335774 100%);
  color: $white;
  transition: all 400ms;

  a, i, li {
    color: $white;
    transition: color 400ms;
  }

  a:visited {
    color: $white;
  }

  footer {
    color: $white;
  }


}

.light {
  // background-color: $white;
  // color: $dark;
  color: #344767;
  background-image: linear-gradient(135deg, #dfdddd 100%, #ffffff 0%);
  transition: all 400ms;
 
}


//.dark {
//  background-color: $dark;
//  color: $white;
//  transition: all 400ms;
//  a, i {
//    color: $white;
//    transition: color 400ms;
//  }
//  a:visited {
//    color: $white;
//  }
//  i:hover {
//    color: $purple;
//  }
//  footer {
//    color: $white;
//  }
//
//  nav {
//    background-color: $dark;
//    transition: $transition;
//  }
//
//  div > section > div:first-child {
//    background-color: #575e64;
//    i:first-child {
//      color: $red;
//    }
//    i:nth-child(2) {
//      color: $yellow;
//    }
//    i:nth-child(3) {
//      color: $green;
//    }
//  }
//
//
//  & > div div {
//    i {
//      color: white;
//    }
//  }
//}